"use client";
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ThemeContainer from "@/components/common/theme/container";
import {hardGray, hover, softWhite, Transition} from "@/styles/globalStyleVars";
import Link from "next/link";
import {usePathname, useRouter} from "next/navigation";
import SelectFieldMenuLocation from "@/components/common/SelectFieldMenuLocation";

const NavigationMenu = () => {
    const [setValue, setSelectedValue] = useState();
    const location = usePathname();
    const router = useRouter()
    const types = [
        {value: 'universities', label: 'Universities'},
        {value: 'courses', label: 'Courses'},
        {value: 'schools', label: 'Schools'},
        {value: 'ielts-pte', label: 'IELTS & PTE'},
    ];
    const handleChange = (event) => {
        router.push(`/${event?.value}`);
        setSelectedValue(event?.label)
    };

    useEffect(() => {
        if(location){
            types.map((e) => {
                if(e?.value === location.replace('/','')){
                    setSelectedValue(e?.label)

                }
            })
        }
    }, [location]);
    return (
        <StyledNavigationMenu className={'navigation-menu-two'}>
            <ThemeContainer colProps={{md: 12}}>
                <nav className="navigation-menu">
                    <ul>

                        <li className={`${location === '/universities' ? 'active' : ''}`}><h4><Link prefetch={true}  href="/universities">Universities</Link></h4></li>
                        <li className={`${location === '/courses' ? 'active' : ''}`}><h4><Link  prefetch={true} href="/courses">Courses</Link></h4></li>
                        <li className={`${location === '/schools' ? 'active' : ''}`}><h4><Link  prefetch={true} href="/schools">Schools</Link></h4></li>
                        <li className={`${location === '/ielts-pte' ? 'active' : ''}`}><h4><Link  prefetch={true} href="/ielts-pte">IELTS & PTE</Link></h4></li>
                    </ul>
                </nav>
                <div className="mobile-navigation-menu">
                    <SelectFieldMenuLocation selectedValue={setValue ? setValue : 'Universities'} placeholder={setValue ? setValue : 'Universities'} onChange={handleChange} selection={types}/>
                </div>
            </ThemeContainer>
        </StyledNavigationMenu>

    );
};
const StyledNavigationMenu = styled.section`
    padding-top:  100px;
    background: ${softWhite};
    position: relative;
    z-index: 1;
    transition: 1s all ${Transition};
    .mobile-navigation-menu{
        display: none;
        padding-bottom: 30px;
        @media(max-width: 992px){
            display: block;
        }
        
        .filter__control{
            border: 1px solid ${hardGray};   
        }
    }
    .navigation-menu {
        ul {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                flex: 0 0 25%;
                max-width: 25%;
                text-align: center;
                padding: 30px 0;
                position: relative;
                
                &:after{
                    position: absolute;
                    content: '';
                    right: 0;
                    height: 70%;
                    width: 1px;
                    top: 0;
                    bottom: 0;
                    background: rgba(54, 50, 49, 0.1);
                }

                
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                h4 {
                    color: ${hardGray};
                    a{
                        color: ${hardGray};
                        &:hover{
                            color: ${hover};
                        }
                    }
                }
                
                &.active{
                    h4 {
                        a{
                            color: ${hover};
                            &:hover{
                                color: ${hardGray};
                            }
                        }
                    }
                }
                

                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    border: none;
                    text-align: right;
                }
            }
        }
        
        @media(max-width: 992px){
            display: none ;
        }
        
        
    }


    @media(max-width: 992px){
        .col-md-12{
            padding: 0 !important;
        }
        .mobile-navigation-menu{
            padding-bottom: 20px;
        }
        margin-top: 0;
        padding-top: 100px;
        background: ${hover};

        .filter__control{
            border-radius: 0 !important;
            background: ${hover};
            padding: 0 15px;
            border: none !important;
        }
        .filter__menu{
            border-radius: 0 !important;
        }
        .filter__placeholder, .filter__single-value{
            color: white !important;   
            font-size: 20px !important;
            font-weight: 500 !important;
            letter-spacing: -0.2px !important;
        }
        .filter__indicator{
            svg{
                line{
                    stroke: white;
                }
            }
        }
    }
`;
export default React.memo(NavigationMenu);
