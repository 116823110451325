import React, {useEffect, useRef, forwardRef, useImperativeHandle, useState} from 'react';
import styled from 'styled-components';
import {Form} from 'react-bootstrap';
import {hover, text, white} from '@/styles/globalStyleVars';
import Select, {components} from 'react-select';

// eslint-disable-next-line react/display-name
const SelectField = forwardRef(({selectedValue, clear, onChange, disabled, prev_id, color, placeholder, selection, option, background, border}, ref) => {
  const selectLocationInputRef = useRef();
  const filterboxRef = useRef(null);
  const [selectMenuLoc, setSelectMenuLoc] = useState('auto');

  // Calculate the available space and set menuPlacement accordingly

  useEffect(() => {
    const handleScroll = () => {
      const filterboxElement = filterboxRef.current;
      if (filterboxElement) {
        const rect = filterboxElement.getBoundingClientRect();
        const { top, bottom } = rect;
        const viewportHeight = window.innerHeight;
        const topSpace = top;
        const bottomSpace = viewportHeight - bottom;


        if (topSpace > bottomSpace) {
          setSelectMenuLoc('top');
        } else {
          setSelectMenuLoc('bottom');
        }
      }
    };

    // Call handleScroll on component mount
    handleScroll();

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [filterboxRef]);

  const types = [
    {value: 'commercial', label: 'Commercial'},
    {value: 'residential', label: 'Residential'},
  ];
  useImperativeHandle(ref, () => ({
    clearValue: () => {
      if (selectLocationInputRef.current && selectLocationInputRef.current.select) {
        selectLocationInputRef.current.select.clearValue();
      }
    },
  }), [selectLocationInputRef]);


  const customStyles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s cubic-bezier(.74,0,.24,.99)',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      color: state.isSelected ? `${white}` : text,
      backgroundColor: state.isSelected ? hover : white,
      margin: 0,
      fontSize: 16,
      cursor: 'pointer',
      lineHeight: '20px',
      paddingLeft: 10,
      paddingRight: 10,
      fontWeight: state.isSelected ? 400 : 400,
      borderBottom: state.options.indexOf(state.data) === state.options.length - 1 ? 'none' : '1px solid #888888', // Check if it's the last item

      '&:hover': {
        backgroundColor: `${hover}`,
        color: `${white}`,
        cursor: 'pointer'
      },

    }), menu: (provided, state) => ({
      ...provided,
      color: '#888888',
      backgroundColor: state.isSelected ? `${white}` : white,
      margin: '15px 0 0 0',
      border: `1px solid ${hover}`,
      padding: 0,
      borderRadius: 10,
      fontSize: 12,
      zIndex: 10,
      borderBottom: '1px solid #888888'

      // width: 200,
    }), menuList: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? `${white}` : white,
      borderRadius: 10,
      fontWeight: '400',
      padding: 0,
      color: '#FFF',
      fontSize: 12,
      maxHeight: '180px', // Set the maximum height for the menu list
      overflowY: 'scroll', // Hide the scrollbar and enable scrolling
      scrollbarWidth: 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none !important',
      },
    }),

  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon/>
      </components.DropdownIndicator>
    );
  };
  const CaretDownIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.828" viewBox="0 0 12.828 7.828">
        <g id="Arrow" transform="translate(17238.414 7077.914)">
          <line id="Line_3886" data-name="Line 3886" x2="5" y2="5" transform="translate(-17237 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_3887" data-name="Line 3887" x1="5" y2="5" transform="translate(-17232 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
        </g>
      </svg>

    );
  };


  useEffect(() => {
    const handleScroll = (e) => {
      if (selectLocationInputRef.current?.select?.state.menuIsOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Add event listener to prevent window scroll when the dropdown is open
    window.addEventListener('wheel', handleScroll, {passive: false});

    return () => {
      // Remove event listener when the component unmounts
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  const handleLocation = (e) => {
    // Remove the following line:
    // setSelectLocation(e);

    // Invoke the onChange callback with the selected value
    if (onChange) {
      onChange(e);
    }
  };




  return (
    <StyledSelectField className={'selector-custom-nzuac'} background={background}>
      <div className="form-group" ref={filterboxRef }>
        <Form.Group controlId="formBasicPhone">
          <Select isDisabled={!!disabled}
            classNamePrefix="filter"
            ref={selectLocationInputRef}
            isSearchable={false}
            menuPlacement={selectMenuLoc}
            isClearable={!!clear}
            options={selection ? selection : types}
            onChange={e => {
              handleLocation(e);
            }}
            placeholder={`${placeholder ? placeholder : 'Select Location'}`} styles={customStyles}
            components={{DropdownIndicator}}
          />

        </Form.Group>

      </div>
    </StyledSelectField>
  );
});


const StyledSelectField = styled.div`
  position: relative;
  min-width: 250px;

  .form-group {
    margin: 0;
  }

  //react select

  .filter--is-disabled {
    //opacity: 0.3;
    cursor: not-allowed;

    .filter__placeholder, .filter__single-value {
      color: #888888 !important;
    }

    .filter__indicator {
      svg {
        line {
          stroke: #888888 !important;
        }
      }
    }
  }

  .filter__control {
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid ${props => props.border || white};
    padding: 0 10px 0 20px;
    cursor: pointer;
    background: ${props => props.background || white};
    border-radius: 100px !important;
    max-width: 250px;
    @media (max-width: 992px) {
      max-width: 100%;

    }

    .filter__value-container, .filter__indicator {
      padding-left: 0;
      padding-right: 0;
    }

    .filter__placeholder {
      color: ${text};
      outline: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
    }

    .filter__single-value {
      color: ${text};
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 24px;
    }

    .filter__indicator-separator {
      display: none;
    }

    .filter__indicators, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .filter__control.filter__control--is-focused {
    border-color: ${hover} !important;

    .filter__indicator {
      svg {
        line {
          //    stroke: #f1f0ee !important;;
        }
      }
    }
  }

  .filter__menu {


    ul {
      display: none;
    }

    .filter__menu_item {

      /* width */

      ::-webkit-scrollbar {
        display: none !important;
      }

      /* Track */

      ::-webkit-scrollbar-track {
        background: transparent;
        display: none;

      }

      /* Handle */

      ::-webkit-scrollbar-thumb {
        display: none;

      }

      /* Handle on hover */

      ::-webkit-scrollbar-thumb:hover {
        //background: transparent;
      }
      &:last-child {
        border: none !important;
      }
    }


  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;

  }




`;

export default React.memo(SelectField);














